<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col md="6" v-if="customer">
        <h3>Vårdplan för {{ customer.firstname }} {{ customer.surname }}</h3>
        <form class="box" @submit.prevent="handleSubmit">
          <b-row>
            <b-col>
              <b-form-textarea
                id="description"
                v-model="careplan.description"
                :state="is_ok('description')"
                placeholder="Enter something..."
                rows="10"
                max-rows="60"
              ></b-form-textarea>
              <b-form-invalid-feedback v-for="item in error.description" :key="item.id">
                {{ item }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          Åtgärder:
          <div>
            <div
              v-for="item in careplansteps"
              draggable="true"
              @dragstart="startDrag($event, item)"
              class="drop-zone"
              @drop="onDrop($event, item.id)"
              @dragover.prevent
              @dragenter.prevent
              :key="item.id"
            >
              <care-plan-step :step_prop="item" v-on:save="getSteps" />
            </div>
          </div>
          <b-row>
            <b-col class="pr-0">
              <b-form-input id="input-title" v-model="newstep" type="text" />
            </b-col>
            <b-col md="auto" class="pl-0">
              <b-button @click="addStep" variant="success"> Lägg till åtgärd </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="">
                <b-button variant="primary" type="submit" :disabled="saving">
                  <translate>Spara</translate>
                </b-button>
                <b-button class="ml-1" variant="secondary" @click="back" :disabled="saving">
                  <translate>Ångra</translate>
                </b-button>
              </div>
            </b-col>
            <b-col> </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { carePlanService, carePlanStepService, customerService } from "../_services";
import { is_ok } from "../_helpers";
import CarePlanStep from "../components/CarePlanStep";
import { utils } from "../_mixins/utils.js";

export default {
  name: "CarePlanPage",
  mixins: [utils],
  props: {
    customerId: {
      type: String,
    },
  },
  components: {
    CarePlanStep,
  },
  data() {
    return {
      customer: undefined,
      error: {},
      careplans: [],
      careplansteps: [],
      careplan: { description: "" },
      saving: false,
      newstep: "",
    };
  },
  created() {
    var self = this;
    self.fetch();
  },
  methods: {
    is_ok(attribute) {
      var self = this;
      if (attribute in self.error) {
        return false;
      }
      return null;
    },
    async fetch() {
      var self = this;
      try {
        self.careplans = await carePlanService.getAll(self.customerId);
        self.getSteps();
        self.customer = await customerService.get(self.customerId);
        if (self.careplans[0]) {
          // Load careplan
          self.careplan = self.careplans[0];
        } else {
          // New careplan
          self.careplan.customer = self.customerId;
        }
      } catch (err) {
        console.error("Caught error");
        console.error(err);
        self.$router.push({ name: "careplans" });
      }
    },
    async handleSubmit() {
      var self = this;
      self.saving = true;
      try {
        if (self.careplan.id === undefined) {
          await carePlanService.post(self.careplan);
        } else {
          await carePlanService.put(self.careplan.id, self.careplan);
        }
        this.$router.go(-1);
      } catch (err) {
        console.error("Caught error");
        console.error(err);
        self.saving = false;
      }
      self.saving = false;
    },
    async getSteps() {
      var self = this;
      self.careplansteps = await carePlanStepService.getAll(self.customerId);
    },
    async addStep() {
      var self = this;
      self.saving = true;
      var careplanstep = {
        customer: self.customerId,
        description: self.newstep,
        steptype: 1,
        active: true,
      };
      try {
        await carePlanStepService.post(careplanstep);
        self.careplansteps = await carePlanStepService.getAll(self.customerId);
        self.newstep = "";
      } catch (err) {
        console.error("Caught error");
        console.error(err);
        self.saving = false;
      }
      self.saving = false;
    },
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    async onDrop(evt, targetId) {
      var self = this;
      const sourceId = evt.dataTransfer.getData("itemID");
      const source = this.careplansteps.find((item) => item.id == sourceId);
      const target = this.careplansteps.find((item) => item.id == targetId);
      await carePlanStepService.move(source.id, target.order);
      self.careplansteps = await carePlanStepService.getAll(self.customerId);
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
};
</script>
