import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import GetTextPlugin from "vue-gettext";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import vSelect from "vue-select";

// Vue2 timepicker - https://github.com/phoenixwong/vue2-timepicker
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import firebase from "firebase/app";
import * as commonmark from "commonmark";

import { router } from "./_helpers";
import { store } from "./_store";
import translations from "../translations.json";

import "./scss/custom.scss";

Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Object.defineProperty(Vue.prototype, "$commonmark", { value: commonmark });
// Use @vuejs-community/vue-filter-date-format
// https://github.com/vuejs-community/vue-filter-date-format
Vue.use(VueFilterDateFormat);

Vue.use(GetTextPlugin, {
  availableLanguages: {
    sv_SE: "Svenska",
    fi_FI: "Suomi",
  },
  defaultLanguage: "sv_SE",
  translations: translations,
  silent: true,
});

Vue.use(vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyANEY7xd1--pKoZTAU3CAK4-df3g9RUkcU",
  authDomain: "dl-care-a1150.firebaseapp.com",
  projectId: "dl-care-a1150",
  storageBucket: "dl-care-a1150.appspot.com",
  messagingSenderId: "856064456528",
  appId: "1:856064456528:web:918baa33c64db4a9009e55",
};

firebase.initializeApp(firebaseConfig);
