<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-3">
      <b-col md="8">
        <b-button
          class="right-button"
          variant="primary"
          :to="{ name: 'note_edit', params: { noteId: 'new' } }"
        >
          Skapa Ny Anteckning
        </b-button>
        <h1>Anteckningar</h1>
        <b-table hover :fields="fields" :items="notes">
          <template v-slot:cell(title)="data">
            {{ data.item.title }}
          </template>
          <template v-slot:cell(author)="data">
            {{ employeeName(data.item.author) }}
          </template>
          <template v-slot:cell(date)="data">
            {{
              new Date(data.item.update_time).toLocaleTimeString("sv-SE", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
          </template>

          <template v-slot:cell(buttons)="data">
            <b-button-group>
              <b-button
                variant="primary"
                :to="{ name: 'note', params: { noteId: data.item.id.toString() } }"
              >
                Visa
              </b-button>
              <b-button
                v-if="data.item.author == employee.id"
                variant="warning"
                :to="{ name: 'note_edit', params: { noteId: data.item.id.toString() } }"
              >
                Editera
              </b-button>
              <b-button v-if="false" variant="danger" @click="handleDelete(data.item)">
                Radera
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { noteService, employeeService } from "../_services";

export default {
  name: "notesPage",
  data() {
    return {
      notes: undefined,
      employees: undefined,
      fields: [
        { key: "title", label: "Titel" },
        { key: "author", label: "Författare" },
        { key: "date", label: "Datum" },
        { key: "buttons", label: "" },
      ],
    };
  },
  computed: {
    employee() {
      return this.$store.getters["employee/employee"];
    },
  },
  created() {
    var self = this;
    self.fetch();
  },
  methods: {
    async fetch() {
      var self = this;
      self.employees = await employeeService.getAll();
      try {
        noteService.getAll().then(function (result) {
          self.notes = result;
          console.debug(result);
        });
      } catch (err) {
        console.error("Caught error");
        console.error(err);
      }
    },
    employeeName(employeeId) {
      var self = this;
      var employee = self.employees.find((x) => x.id === employeeId);
      if (employee) {
        return employee.user.firstname + " " + employee.user.surname;
      }
      return "";
    },
    handleDelete(note) {
      console.debug(note);
      // #TODO
    },
  },
};
</script>
