// import config from 'config';
import { getApiRequest, postApiRequest, putApiRequest, deleteApiRequest } from "../_helpers";

const path = "/note/";

export const noteService = {
  getAll,
  get,
  post,
  put,
  remove,
};

async function getAll() {
  return getApiRequest(path);
}

function get(id) {
  return getApiRequest(path + id + "/");
}

function post(data) {
  return postApiRequest(path, data, {});
}

function put(id, data) {
  return putApiRequest(path + id + "/", data, {});
}

function remove(id) {
  return deleteApiRequest(path + id + "/");
}
