<template>
  <b-row>
    <b-col md="auto pr-0">
      <b-row no-gutters>
        <b-icon
          variant="info"
          icon="arrow-up-square-fill"
          scale="0.8"
          aria-hidden="true"
          @click="move(-1)"
        />
      </b-row>
      <b-row no-gutters>
        <b-icon
          variant="info"
          icon="arrow-down-square-fill"
          scale="0.8"
          aria-hidden="true"
          @click="move(1)"
        />
      </b-row>
    </b-col>
    <b-col class="pr-0 pl-0">
      <b-form-input v-model="step.description" type="text" />
    </b-col>
    <b-col>
      <v-select
        v-model="step.steptype"
        :options="typeOptions"
        label="text"
        :reduce="(object) => object.value"
      >
      </v-select>
    </b-col>
    <b-col v-if="step.steptype == 4">
      <b-button @click="editChoices()"> Ändra alternativ </b-button>
    </b-col>
    <b-modal :id="'stepchoice-modal-' + step.id" size="lg" hide-footer>
      <step-choices :step="step" />
    </b-modal>
    <b-col md="auto" class="pl-0">
      <b-button variant="success" v-if="dirty" @click="updateStep()"> Spara ändring </b-button>
      <b-button variant="danger" @click="removeStep()"> Ta bort åtgärd </b-button>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import { carePlanStepService } from "../_services";
import StepChoices from "../components/StepChoices";
export default {
  name: "CarePlanStep",
  components: {
    vSelect,
    StepChoices,
  },
  props: {
    step_prop: {
      type: Object,
    },
  },
  data() {
    return {
      step: undefined,
      description: {},
      steptype: 1,
      typeOptions: [
        { value: 1, text: "Checkbox" },
        { value: 2, text: "Ja/Nej" },
        { value: 3, text: "Värde" },
        { value: 4, text: "Flerval" },
      ],
    };
  },
  computed: {
    dirty() {
      return this.description != this.step.description || this.steptype != this.step.steptype;
    },
  },
  created() {
    this.step = this.step_prop;
    this.description = this.step.description;
    this.steptype = this.step.steptype;
  },
  methods: {
    async updateStep() {
      var self = this;
      self.saving = true;
      try {
        await carePlanStepService.put(this.step.id, this.step);
        this.$emit("save");
        self.steptype = self.step.steptype;
      } catch (err) {
        console.error("Caught error");
        console.error(err);
        self.saving = false;
      }
      self.saving = false;
    },
    async removeStep() {
      var self = this;
      self.saving = true;
      this.step.active = false;
      try {
        await carePlanStepService.put(this.step.id, this.step);
        this.$emit("save");
      } catch (err) {
        console.error("Caught error");
        console.error(err);
        self.saving = false;
      }
      self.saving = false;
    },
    async move(direction) {
      var self = this;
      const new_order = self.step.order + direction;
      await carePlanStepService.move(self.step.id, new_order);
      this.$emit("save");
    },
    editChoices() {
      this.$bvModal.show("stepchoice-modal-" + this.step.id);
    },
  },
};
</script>
